<template>
  <v-container>
    <v-row dense>
        <v-col cols="12" class="pa-5 login_form rounder">
          <form @submit.prevent="submit" :id="form_id">
            <div class="form_inner">
              <div class="input_holder">
                <v-text-field
                  v-model="email"
                  :error-messages="emailErrors"
                  :label="t_email"
                  required
                  v-eager
                  type="email"
                  solo
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                ></v-text-field>
              </div>
              
            </div>
            <v-btn class="mt-5 submit_btn ubuntu_b" color="black" type="submit" :disabled="submitStatus === 'PENDING'">
                Povrat
            </v-btn>
            <div class="text-center pt-2">
              <p class="typo__p" v-if="submitStatus === 'OK'">Link za povrat je poslan na Vašu Email adresu</p>
              <p class="typo__p error-txt" v-if="submitStatus === 'ERROR'">Molimo Vas ispunite formu pravilno</p>
              <p class="typo__p" v-if="submitStatus === 'PENDING'">Šaljem...</p>
              <p class="typo__p error-txt" v-if="submitError !== ''">{{ submitError }}</p> 
            </div>
            <div class="text-center forgot_pass small_txt" >
              <p class="max_w250">Molimo Vas unesite Emali adresu povezanu sa Vašim računom kako bi Vam mogli poslati link za resetiranje lozinke</p>
            </div>
          </form>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      form_id: 'forgotPass',
      email: '',
      form_enabled: false,
      t_email:'Email'
    }
  },
  mixins: [validationMixin],
  validations: {
    email: {
      email,
      required
    },
  },
  computed: {
    ...mapGetters({
      submitStatus: 'forms/submitStatus',
      submitError: 'forms/submitError',
    }),
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.required && errors.push('Email je obavezan')
      !this.$v.email.email && errors.push('Neispravan Email')
      return errors
    },
  },
  methods: {
    submit () {
      var formPass = false
      this.$v.$touch()
      formPass = this.$v.$invalid
      if (formPass) {
        this.$store.dispatch('forms/changeSubmitStatus','ERROR')
      } else {
        this.$store.dispatch('forms/changeSubmitStatus','PENDING')
        let formData = { email: this.email }
        this.$store.dispatch('forms/submitForm', { form: this.form_id, data: formData })
      }
    },
  },
  destroyed(){
      this.$store.dispatch('forms/changeSubmitStatus',null)
      this.$store.dispatch('forms/changeSubmitError',null)
  }
};
</script>
<style scoped>
  .login_form{
      background-color: rgba(242,223,215,.79);
    }
</style>
